import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import "./Desktop.css";
import "./new.css";
import "./phone.css";
import "./tailwind.css"
const Desktop1 = ({ className = "" }) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 769);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch("https://api.hazirlan.ai/api/mail-list/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        const data = await response.json();
        setIsSubmitted(true);
        setError("");
      } else {
        const data = await response.json();
        
        if (data.errors && data.errors.email) {
          
          setError(data.errors.email[0]);
        } else {
          
          setError(data.message || "Something went wrong");
        }
      }
    } catch (error) {
      setError("Network error. Please try again later.");
    }
  };

  return (
    <div className={`desktop ${className}`}>
      {isSmallScreen ? (
        <>
        <header className="header">
          <img className="logo-icon" alt="" src="/logo@2x.png" />
        </header>
        </>
      ) : (
        <>
          <header className="header">
            <img className="logo-icon" alt="" src="/logo@2x.png" />
            <a
                className="haberdar-ol"
                onClick={() => document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' })}
              >
                Haberdar Ol!
            </a>
          </header>
        </>
      )}
      <section className="top-banner-wrapper -translate-y-12 sm:translate-y-0">
        <div className="top-banner">
          <div className="left-side">
            <h1 className="trkiyenin-lk-yapay-container">
              <div>
                <p className="trkiyenin-lk">{`Yapay Zeka Destekli Matematik Soru Bankası`}</p>
              </div>
            </h1>
            <div className="tyt-ve-ayt-container">
              <span className="tyt-ve-ayt">{`TYT ve AYT için hemen `}</span>
              <i className="hazrlan">hazırlan!</i>
            </div>
            <div className="buttons">
              <img
                className="app-store-icon"
                loading="lazy"
                alt=""
                src="/group.svg"
              />
              <img
                className="app-store-icon"
                loading="lazy"
                alt=""
                src="/googleplay.svg"
              />
            </div>
            {isSmallScreen ? (
              <>
                <div className="header-2">
                    <a
                        className="haberdar-ol"
                        onClick={() => document.getElementById('newsletter').scrollIntoView({ behavior: 'smooth' })}
                      >
                        Haberdar Ol!
                    </a>
                </div>
              </>
              ) : (
                <> 
                
                </>
              )}
          </div>
          <div className="banner-content-div">
            <img
              className="banner-content-icon"
              loading="lazy"
              alt=""
              src="/image-frame@2x.png"
            />
          </div>
          
        </div>
      </section>
      <section className="three-specs-wrapper">
        <div className="three-specs">
          <div className="card1">
            <img
              className="computer-icon"
              loading="lazy"
              alt=""
              src="/computer@2x.png"
            />
            <b className="h3-feature-title-sec-1">İstediğin Cihaz</b>
            <div className="fixed-width-fixed-height-sec-" />
            <div className="paragraph-feature-description-container">
              <p className="trkiyenin-lk">{`İster tabletinle, ister telefonunla çalış.`}</p>
              <p className="trkiyenin-lk">Ortama takılı kalma</p>
            </div>
          </div>
          <div className="card1 card2">
            <img
              className="digitalization-icon"
              loading="lazy"
              alt=""
              src="/digitalization@2x.png"
            />
            <b className="h3-feature-title-sec-1">Yapay Zeka</b>
            <div className="fixed-width-fixed-height-sec-1" />
            <div className="paragraph-feature-description-container1">
              <p className="trkiyenin-lk">{`Tüm sorular senin profiline göre `}</p>
              <p className="trkiyenin-lk">yapay zekayla önüne çıkıyor.</p>
            </div>
          </div>
          <div className="card1 card3">
              <img
                className="digitalization-icon"
                loading="lazy"
                alt=""
                src="/communication@2x.png"
              />
              <b className="h3-feature-title-sec-1">Binlerce soru</b>
              <div className="fixed-width-fixed-height-sec-1" />
              <div className="paragraph-feature-description-container1">
                <p className="trkiyenin-lk">{`Sınav sistemine uygun, `}</p>
                <p className="trkiyenin-lk">binlerce modern tipte soru</p>
              </div>
          </div>
        </div>
      </section>
      <section className="banner-1-wrapper">
        <div className="banner-1">
          <div className="iphone-12-pro-div">
            <img
              className="iphone-12-pro"
              loading="lazy"
              alt=""
              src="/iphone-12-pro.png"
            />
          </div>
            <div className="kiiselletirilmi-mfredat-parent">
              <h3 className="kiiselletirilmi-mfredat">
                Kişiselleştirilmiş Müfredat
              </h3>
              <div className="gelimi-yapay-zekayla-container">
                <p className="trkiyenin-lk">{`Gelişmiş yapay zekayla hazırladığımız müfredatımızla `}</p>
                <p className="trkiyenin-lk">
                  zayıf olduğun konularda gelişmeni sağlıyoruz
                </p>
              </div>
          </div>
        </div>
      </section>
      <section className="banner-2-wrapper">
        <div className="banner-2 h-full pl-8">
          <div className="sre-bazl-performans-analizi-parent pt-4 h-full">
            <h3 className="kiiselletirilmi-mfredat hidden sm:block">
              Süre Bazlı Performans Analizi
            </h3>
            <div className="gelimi-yapay-zekayla-container hidden sm:block">
              <p className="trkiyenin-lk">
                Akıllı sayaç özelliğiyle soru çözme hızını ölçüyor,
              </p>
              <p className="trkiyenin-lk">
                konuya özel raporlarla seni sınav ortamına hazırlıyoruz
              </p>
            </div>
          </div>
          <div className="iphone-13-icon-div flex">
          <div className="parent-div flex flex-col -translate-y-4 translate-x-12">
          <h3 className="kiiselletirilmi-mfredat block sm:hidden">
              Süre Bazlı Performans Analizi
            </h3>
            <div className="gelimi-yapay-zekayla-container block sm:hidden ">
              <p className="trkiyenin-lk">
                Akıllı sayaç özelliğiyle soru çözme hızını ölçüyor,konuya özel raporlarla seni sınav ortamına hazırlıyoruz
              </p>
            </div>
            </div>
            <img
              className="iphone-13-icon -translate-y-8 sm:-translate-y-0 sm:w-full"
              loading="lazy"
              alt=""
              src="/iphone-13@2x.png"
            />
          </div>
          
        </div>
      </section>
      <section className="mid-large-wrapper ">
        <div className="mid-large">
          <div className="iphone-15-pro-div hidden sm:block ">
            <img
              className="iphone-15-pro"
              loading="lazy"
              alt=""
              src="/frame-17@2x.png"
            />
          </div>
          <div className="baaryla-ulamak-iin-hemen-h-wrapper">
            <div className="baaryla-ulamak-iin-container">
              <span>
                <p className="baaryla-ulamak-iin">{`Başarıyla ulaşmak için `}</p>
                <p className="baaryla-ulamak-iin">
                  <span className="tyt-ve-ayt">{`hemen `}</span>
                  <i className="hazrlan1">hazırlan!</i>
                </p>
              </span>
            </div>
          </div>
          <div className="iphone-15-pro-div">
            <img
              className="iphone-15-pro h-full"
              loading="lazy"
              alt=""
              src="/iphone-15-pro@2x.png"
            />
          </div>
        </div>
      </section>
      
      <section className="banner-3-wrapper ">
        <div className="banner-3">
          <div className="iphone-15-icon-div">
            <img
              className="iphone-15-icon w-full"
              loading="lazy"
              alt=""
              src="/iphone-15@2x.png"
            />
            <div className="parent-div flex flex-col">
            <h3 className="dzenli-denemeler sm:hidden -translate-x-12 first-child mr-2 mt-4">Düzenli Denemeler</h3>
            <div className="gelimi-yapay-zekayla-container sm:hidden second-child -translate-x-12 " >
              <p className="trkiyenin-lk">
                Kapsamlı sınavlarla sınav atmosferine hazırlıyor,
              </p>
              <p className="trkiyenin-lk">
                gelişimini sürekli takip ediyoruz
              </p>
            </div>
            </div>
           
          </div>
          <div className="dzenli-denemeler-parent">
            <h3 className="dzenli-denemeler hidden sm:block">Düzenli Denemeler</h3>
            <div className="gelimi-yapay-zekayla-container hidden sm:block">
              <p className="trkiyenin-lk">
                Kapsamlı sınavlarla sınav atmosferine hazırlıyor,
              </p>
              <p className="trkiyenin-lk">
                gelişimini sürekli takip ediyoruz
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="banner-4-wrapper">
        <div className="banner-4 bder ">
            <div className="sre-bazl-performans-analizi-parent">
              <h3 className="dzenli-denemeler hidden sm:block">Liderlik Tabloları</h3>
              <div className="gelimi-yapay-zekayla-container hidden sm:block">
                <p className="trkiyenin-lk">
                  Seviyene göre ligler oluşturuyor,
                </p>
                <p className="trkiyenin-lk">
                  akranlarının arasındaki yerini görmeni sağlıyoruz
                </p>
              </div>
            </div>
            <div>
            <div className="sm:image-23-icon-div -translate-x-24 -translate-y-4 pt-0  flex">
            <div className="translate-x-20 mr-3 pl-4 h-full">
            <h3 className="dzenli-denemeler block sm:hidden">Liderlik Tabloları</h3>
              <div className="gelimi-yapay-zekayla-container block sm:hidden">
                <p className="trkiyenin-lk">
                  Seviyene göre ligler oluşturuyor,akranlarının arasındaki yerini görmeni sağlıyoruz
                </p>
              </div>
            </div>
              <img
                className="image-23-icon w-full object-cover object-top -translate-y-2 translate-x-8 sm:translate-x-8 sm:translate-y-16"
                loading="lazy"
                alt=""
                src="/image-23@2x.png"
              />
            </div>
             </div>
        </div>
      </section>
      <section id="newsletter" className="newsletter-banner-wrapper" style={{ padding: "20px", borderRadius: "20px", background: "#f5f5f5" }}>
      <div className="newsletter-banner">
        <div className="newsletter-form" >
          <div className="hazirlanai-bltenine-abone-container">
            <i className="tyt-ve-ayt" style={{ fontWeight: "bold", fontSize: "24px" }}>
              hazirlan.ai
            </i>
            <span style={{ fontWeight: "normal", fontSize: "24px", marginLeft: "10px" }}>
              bültenine abone olun!
            </span>
          </div>
          {!isSubmitted ? (
            <form className="subscription-form pb-4 sm:pb-0" onSubmit={handleFormSubmit} >
              <div className="input-fields pt-4 sm:pt-0">
                <input
                  className="e-mail"
                  type="email"
                  placeholder="e-mail"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="input-fields1">
                <button
                  className="kaydol"
                  type="submit"
                >
                  Kaydol
                </button>
              </div>
            </form>
          ) : (
            <p style={{ fontSize: "18px", fontWeight: "bold", marginTop: "20px" }}>Teşekkürler, abone oldunuz!</p>
          )}
          {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
        </div>
      </div>
    </section>
      <div className="footer-wrapper">
        <footer className="footer">
          <div className="social-media-buttons">
            <a href="https://www.instagram.com/hazirlan.ai/" target="_blank" rel="noopener noreferrer">
              <img
                className="social-icons"
                loading="lazy"
                alt="Instagram"
                src="/social-icons-2.svg"
              />
            </a>
            <a href="https://www.linkedin.com/company/hazirlan-ai/" target="_blank" rel="noopener noreferrer">
              <img
                className="social-icons"
                loading="lazy"
                alt="LinkedIn"
                src="/social-icons-3.svg"
              />
            </a>
          </div>
          <div className="hazirlanai-2024-tm-haklar-wrapper">
            <div className="hazirlanai-2024-container">
              <i className="hazirlanai1">hazirlan.ai ©</i>
              <span> 2024 Tüm hakları saklıdır.</span>
            </div>
          </div>
        </footer>
      </div>

    </div>
  );
};

Desktop1.propTypes = {
  className: PropTypes.string,
};

export default Desktop1;